import { useState } from 'react'
import { intersection, isEmpty } from 'lodash'

import { useAuth } from '@praxis/component-auth'

import apiConfig from '../config/apiConfig'

const useUser = () => {
  const {
    session: {
      userInfo: { accessToken, email, lanId, memberOf },
    },
  } = useAuth()

  const isUser = !isEmpty(
    intersection(memberOf, apiConfig.auth.requiredPermissions.user),
  )

  const isAdmin = !isEmpty(
    intersection(memberOf, apiConfig.auth.requiredPermissions.admin),
  )

  const isCarrier = !isEmpty(
    intersection(memberOf, apiConfig.auth.requiredPermissions.carrier),
  )

  const isAuction = !isEmpty(
    intersection(memberOf, apiConfig.auth.requiredPermissions.auction),
  )

  const isDispatcher = !isEmpty(
    intersection(memberOf, apiConfig.auth.requiredPermissions.dispatcher),
  )

  const isCarrierEdiOnboardingUser = !isEmpty(
    intersection(
      memberOf,
      apiConfig.auth.requiredPermissions.carrierEdiOnboarding,
    ),
  )

  const isPrivateFleetAdmin = !isEmpty(
    intersection(
      memberOf,
      apiConfig.auth.requiredPermissions.privateFleetAdmin,
    ),
  )

  const isPrivateFleetDispatcher = !isEmpty(
    intersection(
      memberOf,
      apiConfig.auth.requiredPermissions.privateFleetDispatcher,
    ),
  )

  const isLoadManagementUser = !isEmpty(
    intersection(memberOf, apiConfig.auth.requiredPermissions.loadManagement),
  )

  const userInfo = {
    accessToken,
    email,
    lanId,
    access: {
      isUser,
      isAdmin,
      isCarrier,
      isAuction,
      isCarrierEdiOnboardingUser,
      isDispatcher,
      isPrivateFleetAdmin,
      isPrivateFleetDispatcher,
      isLoadManagementUser,
      hasBaseAccess:
        isUser ||
        isAdmin ||
        isCarrier ||
        isAuction ||
        isCarrierEdiOnboardingUser ||
        isDispatcher ||
        isPrivateFleetAdmin ||
        isPrivateFleetDispatcher ||
        isLoadManagementUser,
    },
  }

  const [user] = useState(userInfo)

  return user
}

export default useUser
