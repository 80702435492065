import { NavLink } from 'react-router-dom'
import { Heading, SideNav, TargetLogo } from '@enterprise-ui/canvas-ui-react'
import featureFlag from '../util/featureFlag'
import useUser from '../hooks/useUser'

export const SideNavigation = ({ isVisible, onRequestClose }) => {
  const {
    access: {
      isUser,
      isAdmin,
      isCarrier,
      isAuction,
      isDispatcher,
      isCarrierEdiOnboardingUser,
      isPrivateFleetAdmin,
      isPrivateFleetDispatcher,
      isLoadManagementUser,
    },
  } = useUser()

  return (
    <SideNav
      data-testid="sideNav"
      suppressAutoOpen
      isVisible={isVisible}
      onRequestClose={onRequestClose}
      hasOverlay
    >
      <SideNav.Header
        data-testid="sideNavLogoHeader"
        as={NavLink}
        to="/"
        onClick={onRequestClose}
      >
        <TargetLogo size="expanded" className="hc-mr-dense" />
        <Heading size={1} className="hc-fs-md">
          Domestic Load Manager
        </Heading>
      </SideNav.Header>

      <SideNav.Navigation data-testid="sideNavMainNavArea">
        {(isUser || isAdmin || isCarrier || isDispatcher) && (
          <>
            <SideNav.NavigationGroup startExpanded="true">
              <SideNav.NavigationItem>Tracking</SideNav.NavigationItem>
              <SideNav.NavigationGroupContent>
                <SideNav.NavigationItem
                  as={NavLink}
                  to="/loadtracking/dashboard"
                  onClick={onRequestClose}
                >
                  Dashboard
                </SideNav.NavigationItem>
                <SideNav.NavigationItem
                  as={NavLink}
                  to="/loadtracking"
                  end
                  onClick={onRequestClose}
                >
                  Load Tracking
                </SideNav.NavigationItem>
              </SideNav.NavigationGroupContent>
            </SideNav.NavigationGroup>

            <SideNav.NavigationItem
              as={NavLink}
              to="/departurelog"
              onClick={onRequestClose}
            >
              Departure Log
            </SideNav.NavigationItem>
          </>
        )}

        {featureFlag(
          ['prod'],
          (isAdmin || isLoadManagementUser) && (
            <>
              <SideNav.NavigationItem
                as={NavLink}
                to="/loadmanagement"
                onClick={onRequestClose}
              >
                Load Management
              </SideNav.NavigationItem>
            </>
          ),
        )}

        {(isPrivateFleetAdmin || isPrivateFleetDispatcher) && (
          <>
            <SideNav.NavigationItem
              as={NavLink}
              to="/dispatch"
              onClick={onRequestClose}
            >
              Dispatch
            </SideNav.NavigationItem>
          </>
        )}

        {isPrivateFleetAdmin && (
          <SideNav.NavigationGroup startExpanded="true">
            <SideNav.NavigationItem>Fleet Management</SideNav.NavigationItem>
            <SideNav.NavigationGroupContent>
              <SideNav.NavigationItem
                as={NavLink}
                to="/fleetmanagement"
                onClick={onRequestClose}
              >
                Drivers
              </SideNav.NavigationItem>
            </SideNav.NavigationGroupContent>
          </SideNav.NavigationGroup>
        )}

        {(isUser || isAdmin || isCarrier || isAuction) && (
          <>
            <SideNav.NavigationGroup startExpanded="true">
              <SideNav.NavigationItem>Auction</SideNav.NavigationItem>

              <SideNav.NavigationGroupContent>
                <SideNav.NavigationItem
                  as={NavLink}
                  to="/loadboard"
                  onClick={onRequestClose}
                >
                  Auction Loads
                </SideNav.NavigationItem>

                {isAdmin && (
                  <>
                    <SideNav.NavigationItem
                      as={NavLink}
                      to="/carrier-eligibility"
                      onClick={onRequestClose}
                    >
                      Carrier Eligibility
                    </SideNav.NavigationItem>
                    <SideNav.NavigationItem
                      as={NavLink}
                      to="/auction-configuration"
                      onClick={onRequestClose}
                    >
                      Auction Configuration
                    </SideNav.NavigationItem>
                  </>
                )}
              </SideNav.NavigationGroupContent>
            </SideNav.NavigationGroup>
            <SideNav.NavigationItem
              as={NavLink}
              to="/notifications"
              onClick={onRequestClose}
            >
              Notifications
            </SideNav.NavigationItem>
          </>
        )}

        {isCarrierEdiOnboardingUser && (
          <SideNav.NavigationItem
            as={NavLink}
            to="/carrieronboarding"
            onClick={onRequestClose}
          >
            Carrier EDI Onboarding
          </SideNav.NavigationItem>
        )}

        {isAdmin && (
          <SideNav.NavigationItem
            as={NavLink}
            to="/admin"
            onClick={onRequestClose}
          >
            Admin
          </SideNav.NavigationItem>
        )}
      </SideNav.Navigation>
    </SideNav>
  )
}
